//Variables

//Main Text Colours
$main-color: #252525;
$main-secondary-color: #ffffff;

//Main Background Colours
$system-color: #458c47;

$align-center: center;
$main-radius: 8px;

//Transitions
$-webkit-transition: .3s ease-in-out;
$-moz-transition: .3s ease-in-out;
$-o-transition: .3s ease-in-out;
$-ms-transition: .3s ease-in-out;
$transition: .3s ease-in-out;