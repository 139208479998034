//Import
@import 'variables';
@import 'typography';

.login-sub-text
{
  .login-sub-title
  {
    font-family: $main-headings;
  }

  .login-sub-para
  {
    font-family: $main-inputs;
  }
}

.loginInputs
{
  font-family: $main-inputs;
  text-align: $align-center;
  border-bottom: 1.3px solid $main-color;
}

.loginTabs
{
  li
  {
    a
    {
      h6
      {
        font-family: $main-headings;
      }
    }
  }
}

.loginCheckBox
{
  font-family: $main-bold;
  color: $main-color;
}

.registerCheckBox
{
  font-family: $main-bold;
  color: $main-color;
}

.forgotPass
{
  font-family: $main-bold;
  color: $main-color;

  &:hover, &:focus, &:active
  {
    color: $system-color;

    -webkit-transition: $-webkit-transition;
    -moz-transition: $-moz-transition;
    -o-transition: $-o-transition;
    -ms-transition: $-ms-transition;
    transition: $transition;
  }
}

.loginBut
{
  background-color: $system-color;
  border-color: $system-color;
  font-family: $main-bold;
  letter-spacing: $alpha-space;
  font-size: $regular-size;
}

.iconColor
{
  color: $main-color!important;
  border-color: $main-color!important;
}

.footer
{
  color: $main-color;
  font-family: $main-sub-headings;
}

.footerLogin
{
  color: $main-secondary-color;
  font-family: $main-sub-headings;

  .login-footer
  {
    color: $main-secondary-color;
  }
}


//Forgot Password Modal
.forgot-title
{
  font-family: $main-headings;
  color: $main-color;
}

.forgotpassInput
{
  font-family: $main-inputs;
  border-bottom: 1.2px solid $main-color;

  &:focus
  {
    border-color: $system-color;
  }
}

.resetPassButt
{
  background-color: $system-color;
  border-color: $system-color;
  font-family: $main-bold;
  letter-spacing: $alpha-space;
  color: $main-secondary-color;

  &:hover
  {
    color: $main-secondary-color;
  }
}

//Error Page
.error-text
{
  font-family: $main-headings;
  letter-spacing: $alpha-space;
}

.error-not-found
{
  font-family: $main-sub-headings;
  letter-spacing: $alpha-space;
}

.error-para
{
  font-family: $main-labels;
  font-size: $regular-size;
  letter-spacing: $alpha-space;
}

//Packages
.package-name
{
  font-family: $main-headings;
}

.pricing-table-price
{
  font-family: $main-sub-headings;
}

.package-group
{
  li
  {
    font-family: $main-inputs;
    letter-spacing: $alpha-space;

    strong
    {
      font-family: $main-labels;
    }
  }
}

.purchase-button
{
  background-color: $system-color;
  border-color: $system-color;
  font-family: $main-bold;
  letter-spacing: $alpha-space;
  color: $main-secondary-color;

  &:hover
  {
    color: $main-secondary-color;
  }
}

.ribbon-colour
{
  background-color: $system-color;
  border-color: $system-color;
  font-family: $main-sub-headings;
  color: $main-secondary-color;
}

.back-but
{
  background-color: $main-secondary-color;
  border-color: $main-secondary-color;
  font-family: $main-sub-headings;
  color: $main-color;
}

//iPhone 5c,5s,5
@media (max-width: 370px) and (min-width: 320px){
  .login-sub-text .login-sub-title
  {
    font-size: $medium-2-size;
  }
}